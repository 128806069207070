<style scoped>
	.template_box {
		width: 100%;
		height: 100%;
		overflow: hidden;
		display: flex;
		flex-direction: column;
	}
	.main_box {
		flex: 1;
		overflow: hidden;
	}
	/* 顶部tab菜单 */
	.header_box {
		background-color: #67CD80;
		display: flex;
		flex-direction: row;
	}
	.header_tab {
		background-color: #67CD80;
		display: flex;
		flex-direction: row;
		border-bottom: 1px solid #19be6b;
		flex: 1;
		overflow-x: auto;
	}
	.header_tab-item {
		text-align: center;
		padding: .5rem 2rem;
		position: relative;
		color: #FFFFFF;
		font-size: .9rem;
	}
	.header_tab-item::after {
		content: "";
		position: absolute;
		right: 0;
		height: 80%;
		top: 10%;
		width: 1px;
		background-color: #FFFFFF;
	}
	.header_tab-item:hover {
		cursor: pointer;
	}
	.header_tab-item.active {
		background-color: #FFFFFF;
		color: #000000;
	}
	.header_tab-item.active .header_tab-item-name {
		font-weight: 600;
	}
	.header_tab-item-icon img {
		width: 2rem;
		height: 2rem;
	}
	.header_tab-item-icon:hover {
		transform: scale(1.25);
	}
	/* 1.用户 */
	.header-user-box .el-button {
		background-color: #67CD80;
		border: none;
	}
	.pop_header-user-box {
		display: flex;
		flex-direction: row;
		align-items: center;
		color: #FFF;
	}
	.user_header-image img {
		width: 2.5rem;
		height: 2.5rem;
		border-radius: 50%;
	}
	.user_info-box {
		padding: 0 1rem;
		color: #FFF;
	}
	.user_info-name {
		padding-bottom: .3rem;
	}
	/* 主体 */
	.main_box-container {
		height: 100%;
	}
	.main_container-box {
		height: 100%;
		overflow: hidden;
	}
	/* 物业名字 */
	.property_name {
		height: 100%;
		display: flex;
		align-items: center;
		font-size: 1.8rem;
	}
</style>

<template>
	<div class="template_box" v-if="userInfo">
		<!-- 顶部tab菜单 -->
		<div class="header_box">
			<div class="header_tab">
				<div class="header_tab-item">
					<div class="property_name">{{company_name}}</div>
				</div>
				<div class="header_tab-item" :class="selectHeaderTabIndex==0?'active':''"
					@click="switchHeaderTab(0,{path:'/homePage',children:[]})">
					<div class="header_tab-item-icon">
						<img src="../assets/icon/sy.png" alt="">
					</div>
					<div class="header_tab-item-name">首页</div>
				</div>
				<div v-for="item in topList" class="header_tab-item" :class="selectHeaderTabIndex==item.id?'active':''"
					@click="switchHeaderTab(item.id,item)">
					<div class="header_tab-item-icon">
						<img :src="item.icon!=''?_IMAGE_URL+item.icon:''" alt="">
					</div>
					<div class="header_tab-item-name">{{item.name}}</div>
				</div>
			</div>
			<div class="header-user-box" style="display: flex;align-items: center;padding-right: 20px;">
				<el-dropdown trigger="click" @command="handleCommand">
					<span class="el-dropdown-link" style="display: flex;cursor: pointer;">
						<div class="block" style="margin-right: 10px;">
							<el-avatar :size="40" :src="require('../assets/icon/dtx.png')"></el-avatar>
						</div>
						<div class="user-name mainftcolor" style="color: #FFF;">
							<div>{{userInfo.account}}</div>
							<div>{{userInfo.name}}</div>
						</div>
					</span>
					<!-- <template #dropdown> -->
					<el-dropdown-menu slot="dropdown">
						<el-dropdown-item icon="el-icon-edit" command="1">修改密码
						</el-dropdown-item>
						<el-dropdown-item icon="el-icon-monitor" command="2">退出登录
						</el-dropdown-item>
					</el-dropdown-menu>
					<!-- </template> -->
				</el-dropdown>
				<!-- <el-popconfirm confirm-button-text='退出' cancel-button-text='不用了' icon="el-icon-info" icon-color="red" title="确定退出登录吗？"
				 @confirm="goLoginOut">
					<el-button slot="reference">
						<div class="pop_header-user-box">
							<div class="user_header-image">
								<img src="../assets/icon/dtx.png" alt="">
							</div>
							<div class="user_info-box">
								<div class="user_info-name">{{userInfo.account}}</div>
								<div class="user_info-name">{{userInfo.name}}</div>
							</div>
						</div>
					</el-button>
				</el-popconfirm> -->
			</div>
		</div>

		<div class="main_box">
			<div class="main_box-container">
				<div class="navigation_left"></div>
				<div class="main_container-box">
					<router-view name="mainContainer"></router-view>
				</div>
			</div>
		</div>

		<el-dialog custom-class="my_dialog" width="30%" top="none" title="修改密码"
			:visible.sync="dialogConfig.updatePassword" destroy-on-close>
			<updatePassword :param="dialogConfig.updatePasswordParam" v-on:childrenEvent="updatePasswordEvent">
			</updatePassword>
		</el-dialog>
	</div>
</template>

<script>
	import updatePassword from './updatePassword.vue';
	var _this;
	export default {
		components: {
			"updatePassword": updatePassword
		},
		name: 'index',
		data() {
			return {
				topList: [],
				selectHeaderTabIndex: 0, //选择头部选项
				userInfo: null,
				company_name: localStorage.getItem("companyName"),
				dialogConfig: {
					updatePassword: false,
					updatePasswordParam: null
				}
			}
		},
		created: function() {
			_this = this;
			_this.getUserInfo();
			_this.selectHeaderTabIndex = localStorage.getItem('topIndex') ? localStorage.getItem('topIndex') : 0;
			_this.getTopMenus();

		},
		mounted: function() {

		},
		beforeRouteEnter: function(to, from, next) {

			// 如果是从 / 进来
			if (to.name == 'index') {
				var userIUnfo = localStorage.getItem('userInfo');
				if (userIUnfo) {
					localStorage.setItem('topIndex', 0);
					next(function(vm) {
						vm.$router.replace({
							path: '/homePage',
							query: {
								i: 0
							}
						})
					});
				} else {
					next(function(vm) {
						vm.$router.replace({
							path: '/login',
							query: {}
						})
					})
				}

			} else {
				next();
			}
		},
		methods: {
			handleCommand(command) {
				if(command == 1){
					_this.updatePasswordOpen();
				}else if(command == 2){
					_this.goLoginOut();
				}
			},
			/**   
			 * @discribe 修改密码
			 * @author:郑凯 
			 * @create_time:2021-5-10 14:03:06 
			 */
			updatePasswordOpen() {
				console.log("来了")
				_this.dialogConfig.updatePassword = true;
			},
			updatePasswordEvent(e) {
				if(e.type==1){
					_this.goLoginOut();
				}
				_this.dialogConfig.updatePassword = false;
			},

			/* 退出登录 */
			goLoginOut() {
				localStorage.removeItem('token');
				localStorage.setItem('topIndex', 0);
				_this.$router.replace({
					path: '/login',
					query: {}
				})
			},

			/* ————————————
			获取个人信息 
			————————————*/
			getUserInfo() {
				var userIUnfo = localStorage.getItem('userInfo');
				console.log(userIUnfo)
				if (userIUnfo) {
					userIUnfo = JSON.parse(userIUnfo);
					_this.userInfo = userIUnfo;
				} else {
					_this.goLoginOut();
				}
			},

			/* 获取顶部菜单 */
			getTopMenus() {
				_this._getApi('/wy/account/getRuleListByAdmin', {
					id: ''
				}).then((res) => {
					if (res.code == 1) {
						_this.topList = res.data;
					} else {

					}
				}).catch((err) => {})
			},

			/* 切换头部 */
			switchHeaderTab(index, object) {
				_this.selectHeaderTabIndex = index;
				localStorage.setItem('topIndex', index);
				localStorage.setItem('topNavObject', JSON.stringify(object));
				if (object.children.length != 0) {
					if (object.children[0].children.length != 0) {
						_this.$router.push({
							path: object.children[0].children[0].path,
							query: {
								i: object.children[0].parentId
							}
						})
					} else {
						_this.$router.push({
							path: object.children[0].path,
							query: {
								i: object.children[0].parentId
							}
						})
					}
				} else {
					_this.$router.push({
						path: object.path,
						query: {
							i: index
						}
					})
				}
			},
		}
	}
</script>
