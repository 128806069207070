<style scoped>
	div,input,textarea{box-sizing: border-box;}
	.page_box_add_community{display: flex;flex-direction: column;overflow: hidden;}
	.page_box_body{overflow: auto;flex: 1;}
	.page_box_footer{padding: 10px 10px 0 10px;text-align: right;}
	/* 表单 */
	
	.z_form_item_row{padding: 10px 0;}
	.z_form_item{flex: 1;display: flex;flex-direction: row;align-items:center ;}
	.z_form_item_label{width: 100px;text-align: right;padding-right: 10px;}
	.z_form_item_value{flex: 1;}
	.z_form input{height: 30px;width: 100%;}
	.z_form textarea{height: 80px;width: 100%;padding: 5px;}
</style>
<style>
	.z_form .el-form-item{margin-bottom: 0;}
	.z_form .el-form-item__content{margin-left: 0!important;}
</style>
<template>
	<div class="page_box_add_community">
		<div class="page_box_header"></div>
		<div class="page_box_body">
			<div class="z_form">
				<el-form label-width="80px" :model="form" :rules="rules" ref="form">
					<el-row :gutter="0" class="z_form_item_row">
						<el-col :span="24">
							<el-form-item prop="name">
								<div class="z_form_item">
									<div class="z_form_item_label">旧密码</div>
									<div class="z_form_item_value">
										<el-input placeholder="必填" type="text" v-model="form.old_password"></el-input>
									</div>
								</div>
							</el-form-item>
						</el-col>
					</el-row>
					<el-row :gutter="0" class="z_form_item_row">
						<el-col :span="24">
							<el-form-item prop="name">
								<div class="z_form_item">
									<div class="z_form_item_label">新密码</div>
									<div class="z_form_item_value">
										<el-input placeholder="必填" type="text" v-model="form.password"></el-input>
									</div>
								</div>
							</el-form-item>
						</el-col>
					</el-row>
					<el-row :gutter="0" class="z_form_item_row">
						<el-col :span="24">
							<el-form-item prop="name">
								<div class="z_form_item">
									<div class="z_form_item_label">确认密码</div>
									<div class="z_form_item_value">
										<el-input placeholder="必填" type="text" v-model="form.sure_password"></el-input>
									</div>
								</div>
							</el-form-item>
						</el-col>
					</el-row>
				</el-form>
			</div>
		</div>
		<div class="page_box_footer">
			<el-button @click="close()">取消</el-button>
			<el-button type="success" :loading="btnSaveLoading" @click="onSubmit">立即修改</el-button>
		</div>
	</div>
</template>

<script>
	var _this;
	export default {
		data() {
			return {
				form: {
					old_password: '',
					password: '',
					sure_password: ''
				},
				rules: null,
				btnSaveLoading:false,
			}
		},
		created: function() {
			_this = this;
		},
		methods: {
			// _** 提交保存
			onSubmit() {
				_this.btnSaveLoading = true;
				var params = _this.form;
				_this._postApi('/wy/account/updatePwd', params).then((res) => {
					if (res.code == 1) {
						_this.btnSaveLoading = false;
						_this.$emit('childrenEvent',{
							type:1
						});
					} else {
						_this.btnSaveLoading = false;
						_this.$confirm(res.msg, '提示', {
							confirmButtonText: '确定',
							cancelButtonText: '取消',
							type: 'warning'
						}).then(() => {

						}).catch(() => {

						});
					}
				}).catch((err) => {

				})
			},
			
			/**   
			* 关闭
			* author:郑凯 
			* create_time:2021-3-11 15:27:08 
			*/
		   close(){
			   _this.$emit('childrenEvent','');
		   }
		}
	}
</script>
